<template>
    <div>
<!--        <page-title :titie_name="$t('m.channel.qudaoguanli')"></page-title>-->
        <el-row class="container">
            <el-row class="sliderbar-nav">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="ShowChannelModal = true">{{$t('m.pubilc.tinajia')}}</el-button>
            </el-row>

            <el-row class="eltable cz-table">
                <el-table
                        ref="multipleTable"
                        :data="Result.list"
                        tooltip-effect="dark">
                    <el-table-column
                            prop="channel"
                            :label="$t('m.channel.bieming')"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="userCount"
                            :label="$t('m.channel.yonghushu')"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="qrCode"
                            class-name="qr-code-col"
                            :label="$t('m.channel.riweima')"
                            align="center">
                        <template slot-scope="scope">
                            <img :src="`${$imageUrl}${scope.row.qrCode}`" class="qrCode">
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="createTime"
                            :label="$t('m.channel.shengchengshijian')"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            :label="$t('m.pubilc.caozuo')">
                        <template slot-scope="scope">
                            <el-button type="text"
                                       class="action-button"
                                       @click="downloadImg(scope.row)">
                                {{$t('m.pubilc.xaizai')}}
                            </el-button>
                            <el-button type="text" style="margin-left: 0px!important;"
                                       @click="goDetail(scope.row)">{{$t('m.pubilc.xiangqing')}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>

            <el-row type="flex" style="justify-content: flex-end">
                <el-pagination
                        background
                        :pageSize="pageSize"
                        :current-page.sync="pageNum"
                        @current-change="pageNumChanged"
                        layout="total,prev, pager, next"
                        :total="Result.total">
                </el-pagination>
            </el-row>
        </el-row>

        <el-dialog :title="$t('m.channel.qudaobieming')"
                   width="500px"
                   :close-on-click-modal="false"
                   @close="AddChannelForm.channel = ''"
                   :visible.sync="ShowChannelModal">
            <el-form ref="form"
                     :rules="rules"
                     :model="AddChannelForm">
                <el-form-item :label="$t('m.channel.qudaoName')" prop="channel">
                    <el-input v-model="AddChannelForm.channel"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="ShowChannelModal = false" size="small">{{$t("m.pubilc.cancel")}}</el-button>
                <el-button type="primary" :loading="Loading" size="small" @click="addChannel">{{$t("m.pubilc.confirm")}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import pageTitle from "components/manager/page_title.vue"
    import {AddChannel,ChannelList} from "request/api/channel.js"

    export default {
        name: "ChannelList",
        components:{
            pageTitle,
        },
        data(){
            return {
                Result:{},
                pageSize:10,
                pageNum:1,
                ShowChannelModal:false,
                AddChannelForm:{
                    channel:''
                },
                Loading:false,
                rules:{
                    channel:[
                        { required: true, message: this.$t("m.channel.shurumingcheng"), trigger: 'blur' },
                        { min: 1, max: 20, message: this.$t("m.channel.chengdauyidaorishizifu"), trigger: 'blur' }
                    ]
                }
            }
        },

        mounted() {
            // this.getList();
        },

        methods:{
            pageNumChanged(page){
                this.pageNum = page;
                this.getList();
            },
            addChannel(){
                let _this = this;
                this.$refs.form.validate((ret) => {
                    if (ret){
                        let data = {
                            channel:_this.AddChannelForm.channel
                        };
                        _this.Loading = true;
                        AddChannel(data).then(res=>{
                            _this.Loading = false;
                            _this.$MESSAGE(res,()=>{
                                _this.$notify.success({
                                    title:_this.$t('m.pubilc.tips'),
                                    message:_this.$t('m.pubilc.tip_success')
                                });
                                _this.ShowChannelModal = false;
                                _this.pageNumChanged(1);
                            });
                        })
                    }else {
                        return false;
                    }
                })
            },

            getList(){
                let _this = this;
                let data = {
                    pageSize:this.pageSize,
                    pageNum:this.pageNum,
                };
                ChannelList(data).then(res=>{
                    if (res.code == 200){
                        _this.Result = res.data
                    }
                })
            },

            //下载图片
            downloadImg(row){
                window.open(`${this.$imageUrl}${row.qrCode}`);
            },

            //跳转到详情
            goDetail(row){
                this.$router.push({
                    path:'/manager/channel/statistic',
                    query:{
                        id:row.id
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @import "../../../assets/style/table";

    .container {
        padding: 15px;
        min-width: 750px;
        min-height: 500px;
        box-shadow: none;
        background: white;
    }

    .sliderbar-nav {
        margin-top: -10px;
        padding: 0 15px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .eltable {
        overflow: visible;
    }

    .qrCode{
        width: 40px;
        height: 40px;
    }
    .qrCode:hover{
        width: 120px;
        height: 120px;
        z-index: 9;
        border: 1px solid dodgerblue;
    }
</style>
<style>
    .qr-code-col{
        padding: 0 !important;
    }
</style>
