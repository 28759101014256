<template>
    <div class="title_box">
        {{titie_name}}
    </div>
</template>
<script>
    export default {
        data(){
            return{

            }
        },
        props:{
            titie_name:{
                type:String,
            }
        }
    }
</script>
<style scoped>
    .title_box{
        width: 100%;
        height: 56px;
        line-height: 56px;
        background: white;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding-left: 21px;
    }
</style>


