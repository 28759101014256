import {
    request
} from 'request/request.js'

/**
 * 添加渠道
 * @param data
 * @returns {*}
 * @author
 */
export const AddChannel = (data)=> {
    return request({
        method: 'post',
        url: '/consumer/minipro/channel/insertChannel',
        data: data
    })
};

/**
 * 获取渠道列表
 * @param data
 * @returns {*}
 * @author
 */
export const ChannelList = (data)=> {
    return request({
        method: 'post',
        url: '/consumer/minipro/channel/list',
        data: data
    })
};
